.blinking {
    animation: blink-bg s ease-in-out;
  }
  
  @keyframes blink-bg {
    0% {
      background-color: #F2EEA1;
      color:#00263E;
    }
    100% {
      background-color: transparent;
      color:#FFF;
    }
  }
  .react-flow__edge-textbg {
    fill: #00263E !important; /* Set the background color */
    color: #fff;
    rx: 4; /* Rounded corners */
    ry: 4; /* Rounded corners */
  }
  .react-flow__edge-text{
    fill:#fff    
}


/* Red blinking effect for removal */
.blinking-red {
  animation: blink-red 1s linear 2; /* Blink twice */
}

@keyframes blink-red {
  0%, 100% {
    background-color: transparent;
  }
  50% {
    background-color: red;
  }
}

.ant-tree-treenode-selected {
    background-color: rgba(0, 140, 185 ,1) !important;
    color: white; /* Optional: Change text color to make it more readable */
  }
  
  .ant-tree-treenode-selected *{
    background-color: rgba(0, 140, 185,1 ) !important;
    color: white; /* Optional: Change text color to make it more readable */
  }
  

.tree {
    background-color: transparent;
    font-family: Messina Sans;
    color:rgba(253, 252, 248, .8)
}

.antContainer {
    height: calc(100vh - 170px);
    width: 100%;
}


.node-value {
    color:#F2EEA1;
}


.ant-tree-switcher_open {
    display: none; 
}


.unsFlex {
    overflow: none; /* Enable vertical scrolling */
    height:100%;
    padding-left: 10px;
    display: flex;
}

.customScroll > * {
    direction: ltr; /* Reset text direction for content inside */
}

.customScroll {
    overflow-y: scroll;
    direction: rtl; /* Set right-to-left direction */
    height: 500px;
}

.customScroll::-webkit-scrollbar {
    width: 5px; /* Customize scrollbar width */
}

.customScroll::-webkit-scrollbar-track {
    background-color: rgba( 0, 38, 62,1); /*transparent; /* Make the track transparent */
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #B6DCE1; /* Customize the thumb color and transparency */
    border-radius: 10px; /* Optional: round the corners */
}