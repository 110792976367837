.bouncey {
  animation: bounce 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  transform: translate(-50%, -100%);
}

.main {
  background: var(--radial-gradient)
}

.bouncey.selected {
  animation: none;
  transform: translate(-50%, -100%);
  z-index: 2;
}

.with-transition {
  transition: ease 0.1s;
}
.label {
  background-color: rgba(18, 28, 46, 0.9);
  color: #fff;
  padding: 0.4em 0.9em 0.4em 0.9em;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.label.highlighted {
  background-color: rgba(240, 250, 180, 0.9);
  color: #121c2e;
  font-weight: bold;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
}

@keyframes bounce {
  0% {
    transform: translate(calc(-50%), calc(-100% - 0px));
  }
  50% {
    transform: translate(calc(-50%), calc(-100% - 2px));
  }
  100% {
    transform: translate(calc(-50%), calc(-100% - 0px));
  }
}
