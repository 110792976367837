.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.navStandardGradient {
  background: var(--gradient-default-nav);
}
.standardGradient {
  background: var(--gradient-default);
  height: 100%;
}
.toBottomGradient {
  background: var(--gradient-default-bottom);
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

 /* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b6b3b3; 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@font-face {
  font-family: "Messina Sans";
  src: url(./fonts/MessinaSansWeb-SemiBold.woff) format("woff");
}

@font-face {
  font-family: "Messina Sans Light";
  src: url(./fonts/MessinaSansWeb-Light.woff) format("woff");
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.screen-height {
  max-height: calC(100vh - 75px);
}
/* div#main-container:has(#factory) {
  height: calc(-40px + 100vh) !important;
} */

@media (max-width: 991px) {
  .assetContainer .paneldetail,
  .assetContainer .discover {
    padding: 15px 15px 15px 15px !important;
  }
}
@media (max-width: 767px) {
  .mobile-height-auto {
    max-height: fit-content !important;
  }
  div#main-container:has(.assetContainer) {
    height: auto !important;
  }
  .MuiTabs-scroller button.MuiButtonBase-root {
    font-size: 14px;
  }
}
