.google-container {
    background-color: #FFF;
    border-radius: 4px;
    justify-content: flex-start;
    box-shadow: rgb(58, 69, 82, 0.3) 0px 2px 8px 0px;
    align-items: center;
    transition: all 0.3s ease;
    break-inside: avoid;
    text-align: center;
}

.google-container:hover {
    cursor: pointer;
    box-shadow: rgba(66,133,244,1) 0px 2px 8px 0px;


}

.radiate {
    text-align: center;
    height: 100vh;
    overflow: hidden;
    background: 
      radial-gradient(circle  at 50% 50%, rgba(3, 101, 125, 0.7) 0%, transparent 100%),
      rgba(22,23,40);
  }