.bubble {
  position: absolute;
  transform: translate(-50%, -150%);
  background-color: var(--beaconcolor);
  border: 2px solid #000000;
  text-align: center;
  border-radius: 19px;
  padding: 5px 10px 5px 10px;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
}

.bubble::after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 8px 6px 0;
  border-color: var(--beaconcolor) transparent transparent;
}
.bubble::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 10px 8px 0;
  border-color: black transparent transparent;
}
.preview-mega-factory div#main-container {
  width: auto !important;
}
.preview-mega-factory .discover {
  top: 60% !important;
  transform: none;
}
.preview-mega-factory div#factory {
  max-width: 36vw;
}
.preview-mega-factory .bottom-buttons {
  position: static;
  padding: 0 0 20px;
}
.preview-mega-factory .process-container-inner {
  max-width: 70vw !important;
}

@media (max-width: 767px) {
  .step-overflow .MuiStepper-horizontal {
    overflow-x: auto;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
  .step-overflow .MuiStepLabel-horizontal {
    min-width: 120px;
  }
}
