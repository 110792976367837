.color-label {
  min-width: 140px;
}
.color-input {
  padding: 0 !important;
  height: 35px;
  width: 100%;
  max-width: 60px;
  cursor: pointer;
}
.color-section {
    display:flex;
    align-items: center;
    margin-bottom:8px
}

@media (max-width:767px){
  .preview-theme .vtec-container {
    transform: scale(.23);
  }
  .preview-theme {
    max-width: 100%;
  }
}

@media (max-width:574px){
  .preview-theme .vtec-container {
    transform: scale(.21);
  }

}