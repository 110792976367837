.tableNode {
    border: 2px solid #00263E;
    border-radius: 10px;
    font-family: Messina Sans Light;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: fit-content;
    width: 250px;
  }
  
.description {
    font-family: Messina Sans Light;
    padding: 4px;
    font-size: 12px;
  
  }
  
  .titlecontainer {
    background-color: #00263E;
    padding:5px;
    color:#FDFCF8;
    font-size: 14px;
    font-family: Messina Sans;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
.stereotype {
    background-color: var(--stereocolor);
    font-family: Messina Sans;
    color:black;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2px 6px 2px 6px;
    border-radius: 10px;
  }