.movingline {
    stroke-dasharray: 5,15;
    stroke-dashoffset: 0;
    animation: dash 2s linear infinite;
}

@keyframes dash {
    to {
      stroke-dashoffset: -20;
    }
  }

.appbeacon-wrapper {
    position: absolute;
    transform: translate(-50%,-150%);
    z-index: 2;

}

.appbeacon {
    position: absolute;
    background-color: var(--beaconcolor);
    border: 2px solid var(--fontcolor);
    transform: translate(-50%,-150%);
    text-align: center;
    z-index: 4;
    color: var(--fontcolor);
    border-radius: 19px;
    padding: 5px 10px 5px 10px;
    font-size:13px;
    font-weight: 700;
    cursor:pointer;
    white-space: nowrap;

}
.appdot {
    content: '';
    z-index: 0;
}
.appdot::after{
    content:'';
    position: absolute;
    width: 10px;
    z-index: 0;
    height:10px;
    left:50%;
    top:calc(100% + 0px);
    transform: translate(-50%,-50%);
    background-color: #B6DCE1;
    border-radius: 100%;
}
.appdot::before{
    content:'';
    position: absolute;
    width: 20px;
    height:20px;
    z-index: 0;
    left:50%;
    top:calc(100% + 0px);
    transform: translate(-50%,-50%);
    background-color: #00263E;
    border-radius: 100%;
    border: 2px solid #fff;
}

.bubble {
    position: absolute;
    transform: translate(-50%,-150%);
    background-color: var(--beaconcolor);
    border: 2px solid #000000;
    text-align: center;
    border-radius: 19px;
    padding: 5px 10px 5px 10px;
    font-size:13px;
    font-weight: 700;
    cursor:pointer;
    white-space: nowrap;

  }
  
  .bubble::after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 8px 6px 0;
    border-color: var(--beaconcolor) transparent transparent;
  }
  .bubble::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 8px 0;
    border-color: black transparent transparent;
  }
  .assetContainer {
    transition:  width 0.5s ease; /* Adjust the duration and easing as needed */
    overflow: hidden; /* To hide the content when width is 0 */
  }

  .paneldetail {
    transition: opacity ease;
    transition-delay: var(--delay);
    transition-duration: var(--duration);
  }
  .discover {
    transition: opacity ease;
    transition-delay: var(--delay);
    transition-duration: var(--duration);
  }

  .temp {
    background: linear-gradient(to right, #032337, #043566);}

.maincontainer {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    width: calc(100vw);
    height: calc(100vh -  65px);
    justify-content: center;
    align-items: center;
}

.vibey {
    animation: vibe 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
    animation-delay: var(--delay);
}
.chip {
    border-radius: 25px;
    background-color: rgba(18, 28, 46, 1);
    color: rgba(242, 249, 187, 1);
    padding:12px;
    font-family: 'Messina Sans';
    font-size: 15px;
    cursor: pointer;
    align-items: center;
    display: flex;
    flex-direction: row;
    z-index: 2;
}

.chip:hover {
    color: #FFF;
}



.active {
    background-color: var(--catcolor);
    color: #00263E;}


.active:hover {
    color: #00263E;;
    border-color: var(--catcolor);
}


@keyframes vibe {
    0% {
        transform: translate(calc(-50%),calc(-150% - 0px));
    }
    50%  {
        transform: translate(calc(-50%),calc(-150% - 2px));
    }
    100% {
        transform: translate(calc(-50%),calc(-150% - 0px));
    }
   }