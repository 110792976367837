:root {
    touch-action: none;
}

.cdmcontainer {
    background-color: #FDFCF8;
    display: flex;
    flex-direction: row;
    gap:60px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    overflow: scroll;
}

.fieldTitle {
  padding:5px;
  font-size: small;
  font-weight: bold;
  border-top: 1px solid #00263E;
}

.groupcontainer {
  width: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap:20px;
  z-index: 2;

}

.tablecontainer {
  border: 2px solid #00263E;
  border-radius: 10px;
  font-family: Messina Sans Light;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: fit-content;
}

.description {
  font-family: Messina Sans Light;
  padding: 4px;
  font-size: 12px;

}

.titlecontainer {
  background-color: #00263E;
  padding:5px;
  color:#FDFCF8;
  font-size: 14px;
  font-family: Messina Sans;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.stereotype {
  background-color: var(--stereocolor);
  font-family: Messina Sans;
  color:black;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:2px 6px 2px 6px;
  border-radius: 10px;
}

.beacon {
    background-color: var(--beaconcolor);
    color:black; 
    padding:0.4em 0.9em 0.4em .9em; 
    font-size:13px;
    font-weight: 700;
    border-radius:4px;
    cursor:pointer;
    white-space: nowrap;
    position: absolute;
    transform: translate(-50%,-150%);
    opacity: 0; /* Initially invisible */
    animation: fadeIn 1s ease forwards; /* Animation definition */
    animation-delay: 1s;
}

.libnode {
    border-radius: 8px;
    background-color: var(--catcolor);
    margin: 5px;
    padding: 8px 16px 8px 3px;
    touch-action: manipulation;
    cursor: pointer;
    font-family: Messina Sans;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


@keyframes fadeIn {
    from {
      opacity: 0; /* Starting opacity */
    }
    to {
      opacity: 1; /* Ending opacity */
    }
  }