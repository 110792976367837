.preview-input input {
    height: 10px;
    padding: 8px 8px;
    font-size: 12px;
    background:#fff;
    border-radius:5px;
}
.preview-toggle {
    height: 25px;
    font-size: 10px !important;
}

.small-card-container>div {
    padding: 0 10px;
}
.small-card-container .MuiGrid-container .MuiGrid-item:first-child svg {
    max-width: 15px;
}
.preview-c-header .MuiCardContent-root {
    padding: 0;
}
.preview-c-header .MuiButtonBase-root {
    padding: 0;
}
.preview-c-header {
    padding: 4px 5px;
}
.preGradientColor{
    background:var(--pre-gradient-default);
}
.preview-theme {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    height: calc(100vh - 400px);
    margin: 0 auto;
    max-height: 325px;
    max-width: 520px;
}
.preview-theme .vtec-container {
    min-width: 1390px;
    transform: scale(.34);
    transform-origin: top left;
    height: 285%;
    overflow: auto;
    background:var(--preview-bg-color)
}
.preview-theme .assets-cards {
    max-height: max-content !important;
}
.preview-theme .vtec-container > div {
    pointer-events: none;
}

@media (max-width:1199px){

    .preview-theme .vtec-container {
        transform: scale(.33);
    }    
    .preview-theme {
        max-width: 460px;
    }
    .color-label {
        min-width: 110px;
    }
}
@media (max-width:991px){
    .preview-theme .vtec-container {
        transform: scale(.33);
    }    
}