.process-container {
  border: 1px solid var(--text-white);
   height: "fit-content";
}

.process-title {
  color: var(--text-white);
  font-weight: 500;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.process-title:hover {
  border-bottom: 1px solid var(--text-white);
  border-top: 1px solid transparent;
  cursor: pointer;
}

.process-title.underlined {
  color: var(--text-white);
  border-top: 1px solid transparent;
  border-bottom: 1px solid var(--text-white);
  box-sizing: border-box;
}