.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
  background: 
    radial-gradient(circle at 40% 50%, rgba(255, 139, 139, .7) 0%, transparent 60%),
    radial-gradient(circle  at 60% 50%, rgba(0, 181, 226, 0.7) 0%, transparent 60%),
    radial-gradient(circle  at 50% 50%, rgba(207, 207, 207, 0.2) 0%, transparent 40%),
    rgba(22,23,40);
}

.main-container {
  width: 100%;
  height: 100%;
}

.loading {
  height: 300px;
  text-align: middle;
}

.top-bar {
  z-index: 3;
  background-color: rgb(250, 251, 252);
}

.hoverClick {
  opacity: .5;
}

.hoverClick:hover {
  cursor: pointer;
  opacity: 1;
}

.oppyPanel {
  transition: transform 0.3s ease; /* Smooth transition for the transformation */
  position: absolute;
  clip-path: inset(0); /* Clip the element to its bounding box */
  
}
.oppyPanel.right {
  transform: translate(200px);
}